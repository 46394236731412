<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.w {
  width: 1200px;
  margin: 0 auto;
}
.liveHeader {
  padding: 0 30px;
  height: 60px;
  background-color: #fff;
  justify-content: space-between;
  span {
    color: #f13232;
  }
  .tabs {
    div {
      cursor: pointer;
      margin-right: 55px;
      font-size: 16px;
      color: #333333;
    }
    .active {
      font-weight: bold;
      color: #0090f0;
    }
  }
}
.liveList {
  padding-bottom: 80px;
}
.liveItem {
  cursor: pointer;
  position: relative;
  margin-top: 30px;
  padding: 0 30px;
  height: 160px;
  background-color: #fff;
  overflow: hidden;

  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  .pos {
    position: absolute;
    top: 0;
    left: 0;
    width: 108px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    color: #0090f0;
    font-size: 12px;
    border-bottom-right-radius: 13px;
    background-color: rgba(0, 140, 244, 0.1);
  }
  .start {
    margin-top: 15px;
    float: right;
    padding: 0 15px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    // background-color: #f2f2f2;
    border-radius: 12px;
  }
  .start1 {
    color: #0090f0;
    background-color: transparent;
  }
  .info {
    margin-top: 8px;
    justify-content: space-between;
    .info-left {
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        .titleImg {
          margin-left: 10px;
          width: 38px;
          height: 26px;
        }
      }
      .other {
        margin-top: 40px;
        div {
          font-size: 12px;
          color: #999999;
          margin-right: 35px;
        }
      }
    }
    .info-right {
      color: #f13232;
      text-align: center;
      width: 150px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      border: 1px solid #f13232;
      border-radius: 20px;
    }
  }
  &:hover {
    .info-right {
      color: #fff;
      background-color: #f13232;
    }
  }
}
</style>
<template>
  <div class="w">
    <div class="m_20"></div>
    <div class="flex1 liveHeader">
      <div class="flex tabs">
        <div
          @click="change(index)"
          :class="{ active: active == index }"
          v-for="(item, index) in tabs"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div>
        共有
        <span>{{ count }}</span
        >门课程
      </div>
    </div>
    <div class="liveList">
      <div class="liveItem" v-for="(item, index) in liveData" :key="index">
        <div class="pos">{{ item.action }}</div>
        <!-- <div class="start" :class="{ start1: item.status == 1 }">
          {{ item.status == 0 ? "还未开始" : "直播中..." }}
        </div>-->
        <div class="start" v-if="item.status == 0">未开播</div>
        <div class="start" v-if="item.status == 2 || item.status == 3">
          已结束
        </div>
        <div
          class="start"
          v-if="item.status == 1"
          :class="{ start1: item.status == 1 }"
        >
          直播中...
        </div>
        <div style="clear: both"></div>
        <div class="flex1 info">
          <div class="info-left">
            <div class="flex title">
              <div>{{ item.name }}</div>
              <div class="titleImg">
                <img
                  v-if="item.is_admission == 0"
                  src="../../assets/img/live/img_2_1.png"
                />
                <img v-else src="../../assets/img/live/img_2_2.png" />
              </div>
            </div>
            <div class="flex other">
              <div>直播时间：{{ item.start_time }}</div>
              <div>主讲人：{{ item.teacher }}</div>
            </div>
          </div>
          <div class="info-right" @click="goDetail(item.id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../bus.js";
import { getLiveList } from "../../assets/js/course";
export default {
  name: "Live",
  data() {
    return {
      active: 0,
      tabs: ["全部", "直播中", "未开始", "有回放"],
      count: "",
      liveData: [],
      search: "",
    };
  },
  mounted() {
    Bus.$on("msg", (e) => {
      this.search = e;
      this.getList();
    });
    this.search = this.$route.query.search;
    this.getList();
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        path: "/detail2",
        query: {
          id: id,
        },
      });
    },
    // 获取直播列表
    getList(status) {
      getLiveList({
        siteId: window.localStorage.getItem("siteId"),
        status: status || "",
        search: this.search || "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.liveData = res.data.live;
          this.count = res.data.count;
        }
      });
    },
    change(i) {
      this.active = i;
      let status = "";
      if (i == 0) {
        this.getList();
        return;
      } else if (i == 1) {
        status = 1;
      } else if (i == 2) {
        status = 0;
      } else if (i == 3) {
        status = 3;
      }
      this.getList(status);
    },
  },
};
</script>